import bundle from "../../../../content/posts/react-native-appcenter/bundle-identifier.png";
import team from "../../../../content/posts/react-native-appcenter/team.png";
import addNewApp from "../../../../content/posts/react-native-appcenter/add-new-app.png";
import connect from "../../../../content/posts/react-native-appcenter/connect-to-repo.png";
import select from "../../../../content/posts/react-native-appcenter/select-repo.png";
import configureBuild from "../../../../content/posts/react-native-appcenter/configure-build.png";
import * as React from 'react';
export default {
  bundle,
  team,
  addNewApp,
  connect,
  select,
  configureBuild,
  React
};